import React from 'react';
import styled from 'styled-components';
import { Column, Grid } from 'src/components/molecules/Grid';
const ImageColumn = styled(Column)`
  ${({ verticalAlign }) => verticalAlign == 'end' && 'margin-top: -3rem'};
`;
const ContentColumn = styled(Column)`
  padding: 3rem 0;
`;
export const ArticleTeaser = ({ image, children, verticalAlign = 'end' }) => (
  <Grid verticalAlign={verticalAlign} columns="1fr 2fr" spacious>
    <ImageColumn verticalAlign={verticalAlign}>{image}</ImageColumn>
    <ContentColumn>{children}</ContentColumn>
  </Grid>
);
