import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';
import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
import { ArticleTeaser } from 'src/components/organisms/ArticleTeaser';
import { FluidImg } from 'src/embeds/image/image-dato';

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCaseStudyBlock');
  customData = JSON.parse(customData);

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        {!!heading && <h2>{heading}</h2>}
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {callouts.map(
          ({ id, heading, caseStudy, image }) =>
            !!caseStudy && (
              <ArticleTeaser
                key={id}
                image={!!image && <FluidImg data={image} maxWidth={null} style={{ marginTop: '-2rem' }} />}
                verticalAlign="center"
              >
                <h5 className="highlight">Customer Stories</h5>
                <h3>{heading}</h3>
                <Button to={`/customers/${caseStudy.slug}`}>Read more</Button>
              </ArticleTeaser>
            ),
        )}
        {ctas.length > 0 && (
          <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
            {ctas.map((cta, i) => {
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Button to={cta.url} {...ctaFlags}>
                    {cta.text}
                  </Button>
                </li>
              );
            })}
          </InlineList>
        )}
      </Section>
    </MDXProvider>
  );
}
